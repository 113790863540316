<template>
  <div class="container-fluid">
    <!--  -->
    <div class="row" v-if="!loading && !$stash.loadedTemplate">
      <div class="col-md-6 mx-auto">
        <create-template></create-template>
      </div>
    </div>

    <busy v-else-if="loading" />

    <!--  -->
    <div class="row HR-VIEW-CLASS-REF-FOR-PDF-GENERATOR" v-if="$stash.loadedTemplate">
      <div
        :class="
          sidebar
            ? 'col-md-9 page-area-print-wide'
            : 'col-md-11 page-area-print-wide'
        "
      >
        <form-area
          :client="client"
          :history="history"
        ></form-area>
      </div>
      <div class="col-md-3 noprint" v-if="sidebar">
        <div class="sticky-top">
          <button
            class="btn btn-secondary mb-3"
            @click="sidebar = false"
            v-tooltip.left="'Hide Toolbar'"
          >
            <i class="fas fa-arrow-right"></i>
          </button>
          <div class="card border-0 bg-secondary mb-3" v-if="client">
            <div class="card-body text-white px-3 py-2">
              <p class="mb-0 text-center">
                <router-link :to="/clients/ + client.id + '#files-tab'">{{
                  client.name
                }}</router-link>
                -
                {{ $stash.loadedTemplate.title }}
              </p>
            </div>
          </div>
          <div
            class="alert alert-danger"
            role="alert"
            v-if="client.has_allergies"
          >
            <div class="row">
              <div class="col-3">
                <b><i class="fas fa-exclamation-triangle"></i></b>
              </div>
              <div class="col-9">
                <p>
                  <small>
                    Client has
                    <router-link
                      :to="/clients/ + client.id + '#allergies-tab'"
                      class="alert-link"
                      >allergies & intolerances</router-link
                    >
                  </small>
                </p>
              </div>
            </div>
          </div>
          <form-builder-toolbar :client="client" :template="template" :resources="resources"/>
          <div class="sidebar-tools">
            <form-builder-options
              class="mb-3"
              v-if="$stash.currentSelectedItem"
              :client="client"
            ></form-builder-options>
            <form-builder-items
              class="mb-3"
              :client="client"
              :template="template"
              :resources="resources"
            ></form-builder-items>
            <!-- <how-to></how-to> -->
            <div class="p-3"><hr></div>
          </div>
        </div>
      </div>
      <div class="col-md-1 noprint" v-else>
        <div class="sticky-top" @click="sidebar = true">
          <button class="btn btn-primary" >
            <i class="fas fa-arrow-left" v-tooltip.left="'Show Toolbar'"></i>
          </button>
          <div
            class="alert alert-danger d-block btn w-50 mt-3"
            role="alert"
            v-if="client.has_allergies"
          >
            <b><i class="fas fa-exclamation-triangle" v-tooltip.left="'Show Warnings'"></i></b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateTemplate from "./partials/CreateTemplate";
import FormArea from "./partials/FormArea";
import FormBuilderItems from "./partials/FormBuilderItems";
import FormBuilderOptions from "./partials/FormBuilderOptions";
import FormBuilderToolbar from "./partials/FormBuilderToolbar";
import HowTo from "./partials/HowTo";

export default {
  props: {
    inputView: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: true,
      template: null,
      client: null,
      unsavedChanges: false,
      resources: [],
      sidebar: true,
      history: [],
      //version: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    fetchClientTemplate() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/documents/" +
            this.$route.params.clientId +
            "/" +
            this.$route.params.templateId
        )
        .then(({ data }) => {
          this.template = data.template;
          this.client = data.client;
          //this.version = data.version;

          this.checkLoaded();
          if (this.template.resources) {
            this.resources = JSON.parse(this.template.resources);
          }
        });
    },
    updateTemplate(silent) {
      this.$axios
        .post(
          this.inputView
            ? process.env.VUE_APP_API_URL +
                "/documents/client-version/" +
                this.$route.params.templateId
            : process.env.VUE_APP_API_URL +
                "/documents/" +
                this.$route.params.templateId,
          {
            data: this.$stash.templateItems,
            resources: this.resources,
            //version: this.version,
          }
        )
        .then(({ data }) => {
          this.$stash.loadedTemplate = data.template;
          this.$stash.unsavedChanges = false;
          if (!silent) {
            this.$EventBus.$emit("alert", data);
          }
          this.addHistory(data.template);
        });
    },
    checkLoaded() {
      if (this.template != null) {
        this.$stash.loadedTemplate = this.template;
        this.$stash.templateItems = this.$stash.loadedTemplate.data;
        this.loading = false;
      }
    },
    clearStore() {
      this.template = null;
      this.$stash.loadedTemplate = null;
      this.$stash.templateItems = [];
      this.$stash.currentSelectedRow = null;
      this.$stash.currentSelectedCol = null;
      this.$stash.currentSelectedColKey = null;
      this.$stash.currentSelectedItem = null;
    },
    addHistory(template) {
      let templateData = JSON.stringify(template.data);
      if (this.history[this.history.length - 1] !== templateData) {
        this.history.push(templateData);
      }
    },
    historyRollback() {
      if (this.history.length > 0) {
        this.history.splice(this.history.length - 1);
        this.$stash.templateItems = JSON.parse(
          this.history[this.history.length - 1]
        );
      }
    },
  },
  created() {
    this.clearStore();
    this.fetchClientTemplate();
    window.addEventListener("beforeunload", (event) => {
      if (this.$stash.unsavedChanges) {
        event.returnValue = `There are unsaved changes, are you sure you want to leave?`;
      }
    });
  },
  beforeDestroy() {
    this.clearStore();
  },
  mounted() {
    this.$EventBus.$on("saveTemplate", (silent = true) => {
      this.updateTemplate(silent);
    });
    this.$EventBus.$on("addResource", (resource) => {
      this.resources.push(resource);
      this.updateTemplate(true);
    });
    this.$EventBus.$on("removeResource", (resource) => {
      this.resources.splice(this.resources.findIndex(r => r.id === resource.id) , 1);
      this.updateTemplate(true);
    });
    this.$EventBus.$on("undo", () => {
      this.historyRollback();
    });
  },
  components: {
    CreateTemplate,
    FormArea,
    FormBuilderItems,
    FormBuilderOptions,
    FormBuilderToolbar,
    HowTo,
  },
};
</script>

<style scoped>
.sticky-top {
  top: 1rem;
}

.warning {
  position: fixed;
  bottom: 0;
  right: 0;
  color: #ff0000;
}

.sidebar-tools {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
